<!--begin::Brand-->
<div class="brand flex-column-auto" id="kt_brand" [ngClass]="brandClasses">
  <!--begin::Logo-->
  <a routerLink="/" class="brand-logo" style="margin: 0 auto"> 
  </a>
  <!--end::Logo-->

  <ng-container *ngIf="asideSelfMinimizeToggle">
    <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
      <span
        [inlineSVG]="
          './assets/media/svg/icons/Navigation/Angle-double-left.svg'
        "
        cacheSVG="true"
        class="svg-icon svg-icon-xl"
      ></span>
    </button>
  </ng-container>
</div>
<!--end::Brand-->

<!--begin::Aside Menu-->
<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
  <ng-container *ngIf="disableAsideSelfDisplay">
    <!--begin::Header Logo-->
    <a routerLink="/">
      <img alt="Logo" [attr.src]="headerLogo" />
    </a>
    <!--end::Header Logo-->
  </ng-container>
  <!--begin::Menu Container-->
  <div
    id="kt_aside_menu"
    class="aside-menu"
    [ngClass]="asideMenuCSSClasses"
    data-menu-vertical="1"
    [attr.data-menu-scroll]="asideMenuScroll"
    [attr.data-menu-dropdown]="asideMenuDropdown"
    data-menu-dropdown-timeout="500"
  >
    <!-- begin::Menu Nav -->
    <ul class="menu-nav" [ngClass]="ulCSSClasses">
      <!-- begin::1 Level -->

      <li class="menu-section sesion">
        <div class="menu-text">
          <div>
            <p style="color: #262626">{{ user.nombre }}</p>
            <span style="color: #262626">{{ sucursal }}</span>
            <fa-icon
              style="margin-left: 4px"
              [icon]="faStore"
              [styles]="{ color: 'white' }"
            ></fa-icon>
          </div>
          <div>
            <fa-icon
              (click)="cambiarSucursal()"
              class="cogicon"
              [icon]="faCog"
              size="2x"
            ></fa-icon>
          </div>
        </div>

        <fa-icon
          class="menu-icon"
          [icon]="faCog"
          size="lg"
          [styles]="{ color: 'white' }"
        ></fa-icon>
      </li>

      <!--   <li
      class="menu-item menu-item-submenu"
      aria-haspopup="true"
      data-menu-toggle="hover"
      routerLinkActive="menu-item-open">
      <a
        class="menu-link menu-toggle"
        routerLinkActive="active"
        href="/error">
        <span
        [inlineSVG]="'./assets/media/svg/icons/Design/Layers.svg'"
          cacheSVG="true"
          class="svg-icon menu-icon"
        ></span>
        <span class="menu-text">Dashboard</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">
          <li class="menu-item menu-item-parent" aria-haspopup="true">
            <span class="menu-link">
              <span class="menu-text">Dashboard</span>
            </span>
          </li>
          <li
          class="menu-item"
          routerLinkActive="menu-item-active"
          aria-haspopup="true"
          data-menu-toggle="hover">
          <a
            class="menu-link"
            routerLink="/dashboard/solicitudes-de-descuento"
            routerLinkActive="active">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Solicitudes de Descuento</span>
          </a>
        </li>
        <li
        class="menu-item"
        routerLinkActive="menu-item-active"
        aria-haspopup="true"
        data-menu-toggle="hover">
        <a
          class="menu-link"
          routerLink="/dashboard/ventas-sin-evidencia"
          routerLinkActive="active">
          <i class="menu-bullet menu-bullet-dot">
            <span></span>
          </i>
          <span class="menu-text">Ventas sin Evidencia</span>
        </a>
      </li>
      <li
          class="menu-item"
          routerLinkActive="menu-item-active"
          aria-haspopup="true"
          data-menu-toggle="hover">
          <a
            class="menu-link"
            routerLink="/dashboard/instalaciones"
            routerLinkActive="active">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Instalaciones</span>
          </a>
        </li>
        </ul>
      </div>
    </li>
 -->

      <li
        class="menu-item"
        aria-haspopup="true"
        routerLinkActive="menu-item-active"
      >
        <a
          class="menu-link"
          routerLink="/nuevo-dashboard"
          routerLinkActive="active"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Design/Union.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span
            *ngIf="user.dashboards.nuevo_dashboard_ventas == true"
            class="menu-text"
            >Dashboard Ventas</span
          >
          <span
            *ngIf="!user.dashboards.nuevo_dashboard_ventas"
            class="menu-text"
            >Dashboard General</span
          >
        </a>
      </li>

      <li
        class="menu-item"
        aria-haspopup="true"
        routerLinkActive="menu-item-active"
      >
        <a class="menu-link" routerLink="/dashboard" routerLinkActive="active">
          <span
            [inlineSVG]="'./assets/media/svg/icons/Design/Layers.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text" translate="MENU.DASHBOARD"></span>
        </a>
      </li>

      <!-- Material-UI -->
      <!-- begin::1 Level -->
      <li
        *ngIf="findPermiso('Administración')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          href="/material"
          routerLinkActive="active"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Code/CMD.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Administración</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Administración</span>
              </span>
            </li>

            <li
              *ngIf="findPermiso('Capital de trabajo')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/direccion/capital"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Capital de trabajo</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Dirección General')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/direccion/direccion-general"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Dirección General</span>
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Administración Mensual')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/administracion/administracion-mensual"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Administración Mensual</span>
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Reporte Cobranzas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/administracion/reporte-cobranza"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reporte Cobranza</span>
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Reporte de Inventario')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/administracion/reporte-inventario"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reporte de Inventario</span>
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Reporte de Gastos Anuales')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/administracion/reporte-gastos-anuales"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reporte de Gastos Anuales</span>
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Reporte de Compras Anuales')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/administracion/reporte-compras-anuales"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reporte de Compras Anuales</span>
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Reporte de Utilidades')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/administracion/reporte-utilidades"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reporte de Utilidades</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Reporte gastos sucursales')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/administracion/reporte-gastos-sucursales"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reporte gastos sucursales</span>
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Reporte de Ventas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/administracion/reporte-ventas"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reporte de Ventas</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Reporte de Ventas Productos')"
              class="menu-item menu-item-submenu"
              routerLinkActive="menu-item-open"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link menu-toggle"
                routerLinkActive="active"
                href="/material/navigation"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reporte de Ventas Prod.</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <i class="menu-arrow"></i>
                <ul class="menu-subnav">
                  <!-- begin::3 Level -->
                  <li
                    class="menu-item"
                    routerLinkActive="menu-item-active"
                    aria-haspopup="true"
                  >
                    <a
                      class="menu-link"
                      routerLink="/administracion/reporte-ventas-productos"
                      routerLinkActive="active"
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">General</span>
                    </a>
                  </li>
                  <li
                    class="menu-item"
                    routerLinkActive="menu-item-active"
                    aria-haspopup="true"
                  >
                    <a
                      class="menu-link"
                      routerLink="/administracion/reporte-ventas-productos/piezas"
                      routerLinkActive="active"
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Piezas</span>
                    </a>
                  </li>
                  <!-- end::3 Level -->
                  <!-- begin::3 Level -->
                  <li
                    class="menu-item"
                    routerLinkActive="menu-item-active"
                    aria-haspopup="true"
                  >
                    <a
                      class="menu-link"
                      routerLink="/administracion/reporte-ventas-productos/ventas"
                      routerLinkActive="active"
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Ventas</span>
                    </a>
                  </li>
                  <li
                    class="menu-item"
                    routerLinkActive="menu-item-active"
                    aria-haspopup="true"
                  >
                    <a
                      class="menu-link"
                      routerLink="/administracion/reporte-ventas-productos/utilidades"
                      routerLinkActive="active"
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Utilidades</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <!-- end::2 Level -->
          </ul>
        </div>
      </li>

      <li
        *ngIf="findPermiso('Recursos Humanos')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          href="/material"
          routerLinkActive="active"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Code/CMD.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Recursos Humanos</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Recursos Humanos</span>
              </span>
            </li>
            <li
              *ngIf="findPermiso('Marcar Entrada/Salida')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                (click)="checador()"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Marcar Entrada/Salida</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Asistencias')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/recursos-humanos/registros"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Registros checador</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Configuración checador')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/recursos-humanos/configuracion"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Configuración checador</span>
              </a>
            </li>

            <!-- end::2 Level -->
          </ul>
        </div>
      </li>

      <!-- 
            <li *ngIf="findPermiso('Dirección')" class="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover" routerLinkActive="menu-item-open">
                <a class="menu-link menu-toggle" routerLinkActive="active" href="/error">
                    <span [inlineSVG]="'./assets/media/svg/icons/General/Star.svg'" cacheSVG="true" class="svg-icon menu-icon"></span>
                    <span class="menu-text">Dirección</span>
                    <i class="menu-arrow"></i>
                </a>
                <div class="menu-submenu">
                    <i class="menu-arrow"></i>
                    <ul class="menu-subnav">
                        <li class="menu-item menu-item-parent" aria-haspopup="true">
                            <span class="menu-link">
            <span class="menu-text">Dirección</span>
                            </span>
                        </li>
                        <li *ngIf="findPermiso('Capital de trabajo')" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link" routerLink="/direccion/capital" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
                                <span class="menu-text">Capital de trabajo</span>
                            </a>
                        </li>
                        <li *ngIf="findPermiso('Dirección General')" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link" routerLink="/direccion/direccion-general" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
                                <span class="menu-text">Dirección General</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li> -->

      <li
        *ngIf="findPermiso('Catálogos')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Catálogos</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Catálogos</span>
              </span>
            </li>

            <li
              *ngIf="findPermiso('Servicios')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/servicios"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Servicios</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Departamentos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/departamentos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Departamentos</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Sucursales')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/sucursales"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Sucursales</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Asesores Externos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/asesores-externos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Asesores Externos</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Instaladores')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/instaladores"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Instaladores</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Repartidores')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/repartidores"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Repartidores</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Almacenes')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/almacenes"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Almacenes</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Dropshipping')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/dropshipping"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Dropshipping</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Personal')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/personal"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Personal</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Metodos de Contacto')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/metodo-contacto"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Métodos de Contacto</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Perfiles')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/perfiles"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Perfiles</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Proveedores')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/proveedores"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Proveedores</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Marcas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/marcas"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Marcas</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Categorías')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/categorias"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Categorías</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Productos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/productos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Productos</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Listas de precio')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/listas-de-precio"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Listas de precio</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Impuestos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/impuestos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Impuestos</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Formas de Pago')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/formas-de-pago"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Formas de Pago</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Tipos de Gasto')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/tipos-de-gasto"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tipos de Gasto</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Canales de venta')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/canales-de-venta"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Canales de venta</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Tipos de gasto')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/tipos-de-gasto"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tipos de gasto</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Conceptos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/conceptos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tipos de egreso</span>
              </a>
            </li>
            <!-- <li
    class="menu-item"
    routerLinkActive="menu-item-active"
    aria-haspopup="true"
    data-menu-toggle="hover">
    <a
      class="menu-link"
      routerLink="/catalogos/bancos"
      routerLinkActive="active">
      <i class="menu-bullet menu-bullet-dot">
        <span></span>
      </i>
      <span class="menu-text">Bancos</span>
    </a>
    </li> -->
            <li
              *ngIf="findPermiso('Cuentas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/cuentas"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cuentas</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Clientes')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/catalogos/clientes"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Clientes</span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li
        *ngIf="findPermiso('Tareas')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          href="/material"
          routerLinkActive="active"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/General/Clipboard.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text"
            >Tareas
            <div *ngIf="badge > 0" class="badge-tareas">
              {{ badge }}
            </div></span
          >
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Tareas</span>
              </span>
            </li>

            <li
              *ngIf="findPermiso('Mi Equipo')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/tareas/mi-equipo"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Mi equipo</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Mis Tareas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/tareas/mis-tareas"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Mis Tareas</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Desgloce')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/tareas/desgloce"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Desgloce</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Configuracion')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/tareas/configuracion"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Configuración</span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li
        *ngIf="findPermiso('Ventas')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Shopping/Dollar.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Ventas</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Ventas</span>
              </span>
            </li>

            <li
              *ngIf="findPermiso('Notas de Venta')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/ventas/nota-de-venta"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Nota de Venta</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Cotizaciones')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/ventas/cotizacion-de-venta"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cotizaciones</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Garantías')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/ventas/garantias"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cambios y devoluciones</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Back Order')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/ventas/back-order"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Back Order</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Excedentes')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/ventas/excedentes"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Excedentes</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Catálogo de Productos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/ventas/catalogo-productos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Catálogo de Productos</span>
              </a>
            </li>
            <!-- <li *ngIf="findPermiso('Cambios y Devoluciones')"
      class="menu-item"
      routerLinkActive="menu-item-active"
      aria-haspopup="true"
      data-menu-toggle="hover">
      <a
        class="menu-link"
        routerLink="/ventas/cambios-y-devoluciones"
        routerLinkActive="active">
        <i class="menu-bullet menu-bullet-dot">
          <span></span>
        </i>
        <span class="menu-text">Cambios y Devoluciones</span>
      </a>
    </li> -->

            <li
              *ngIf="findPermiso('Solicitud de Descuentos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/ventas/solicitud-de-descuentos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Solicitud de Descuentos</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Ventas sin Evidencia')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/ventas/ventas-sin-evidencia"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Ventas sin Evidencia</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Ordenes de Venta')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/ventas/ordenes-de-venta"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Ordenes de Venta</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li
        *ngIf="findPermiso('CRM')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="
              './assets/media/svg/icons/Communication/Adress-book2.svg'
            "
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">CRM</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li
              *ngIf="findPermiso('Panel')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/crm/panel"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Ranking</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Reporte créditos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/crm/reporte-creditos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reporte créditos</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Embudo')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/crm/embudo"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Embudo</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Calendario')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/crm/calendario"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Calendario</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Configuración')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/crm/configuracion"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Configuración</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li
        *ngIf="findPermiso('Contact Center')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="
              './assets/media/svg/icons/Communication/Active-call.svg'
            "
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Contact Center</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Contact Center</span>
              </span>
            </li>

            <li
              *ngIf="findPermiso('Contact Center Citas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/contactcenter/citas"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Citas</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Contact Center General')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/contactcenter/general"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">General</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Contact Center Campanas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/contactcenter/campanas"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Campañas</span>
              </a>
            </li> 
            <li
              *ngIf="findPermiso('Contact Center Reportes Campanas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/contactcenter/reportes/campanas" 
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reportes campañas</span>
              </a>
            </li> 
          </ul>
        </div>
      </li>
      <li
        *ngIf="findPermiso('Compras')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart1.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Compras</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Compras</span>
              </span>
            </li>

            <li
              *ngIf="findPermiso('Requisición')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/compras/requisicion"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Requisición</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Orden de compra')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/compras/orden-de-compra"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Orden de compra</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Comprobación de gastos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/compras/comprobacion-gastos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Comprobación de gastos</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Reporte')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/compras/reporte"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reporte de Punto de Reorden</span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li
        *ngIf="findPermiso('Almacén')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-4-blocks.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Almacén</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Almacén</span>
              </span>
            </li>

            <li
              *ngIf="findPermiso('Inventario')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/almacen/inventario"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inventario</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Ajuste de inventario (Entrada)')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/almacen/ajuste-inventario-entrada"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Ajuste de inventario (Entrada)</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Ajuste de inventario (Salida)')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/almacen/ajuste-inventario-salida"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Ajuste de inventario (Salida)</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Importador de Ajustes de Inventario')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/almacen/importador-ajustes"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text"
                  >Importador de Ajustes de<br />Inventario</span
                >
              </a>
            </li>
            <li
              *ngIf="findPermiso('Recepción de mercancías')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/almacen/recepcion-mercancia"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Recepción de mercancías</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Recepción CEDIS')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/almacen/recepcion-cedis"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Recepción CEDIS</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Inventario de Dropshipping')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/almacen/inventario-dropshipping"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inventario de Dropshipping</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Traspasos de Mercancia')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/almacen/traspasos-mercancia"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Traspasos de Mercancia</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Generar Traspasos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/almacen/generar-traspasos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Generar Traspasos</span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li
        *ngIf="findPermiso('Logística')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Map/Direction2.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Logística</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Logística</span>
              </span>
            </li>

            <li
              *ngIf="findPermiso('Rutas y Entregas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/logistica/rutas-entregas"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Rutas y Entregas</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Repartidores')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/logistica/repartidores"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Repartidores</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Instalaciones')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/logistica/instalaciones"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Instalaciones</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Instaladores')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/logistica/instaladores"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Instaladores</span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li
        *ngIf="findPermiso('Tesorería')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Home/Home.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Tesorería</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Tesorería</span>
              </span>
            </li>

            <li
              *ngIf="findPermiso('Ingresos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/tesoreria/ingresos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Ingresos</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Egresos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/tesoreria/egresos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Egresos</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Transferencia Entre Cuentas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/tesoreria/transferencia-entre-cuentas"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Transferencia Entre Cuentas</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Cobranza')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/tesoreria/cobranza"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cobranza</span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <!-- Material-UI -->
      <!-- begin::1 Level -->
      <li
        *ngIf="findPermiso('Finanzas')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          href="/material"
          routerLinkActive="active"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Shopping/Credit-card.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Finanzas</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Finanzas</span>
              </span>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('CXC')"
              class="menu-item menu-item-submenu"
              aria-haspopup="true"
              data-menu-toggle="hover"
              routerLinkActive="menu-item-open"
            >
              <a
                class="menu-link menu-toggle"
                href="/material/form-controls"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">CXC</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <i class="menu-arrow"></i>
                <ul class="menu-subnav">
                  <!-- begin::3 Level -->
                  <li
                    class="menu-item"
                    aria-haspopup="true"
                    routerLinkActive="menu-item-active"
                  >
                    <a
                      class="menu-link"
                      routerLink="/finanzas/cxc/cuentas-por-cobrar"
                      routerLinkActive="active"
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Cuentas por cobrar</span>
                    </a>
                  </li>
                  <!-- end::3 Level -->
                  <!-- begin::3 Level -->
                  <li
                    class="menu-item"
                    aria-haspopup="true"
                    routerLinkActive="menu-item-active"
                  >
                    <a
                      class="menu-link"
                      routerLink="/finanzas/cxc/estado-de-cuenta"
                      routerLinkActive="active"
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Estado de cuenta</span>
                    </a>
                  </li>
                  <!-- end::3 Level -->
                </ul>
              </div>
            </li>
            <!-- end::2 Level -->
            <!-- Navigation -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('CXP')"
              class="menu-item menu-item-submenu"
              routerLinkActive="menu-item-open"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link menu-toggle"
                routerLinkActive="active"
                href="/material/navigation"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">CXP</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <i class="menu-arrow"></i>
                <ul class="menu-subnav">
                  <!-- begin::3 Level -->
                  <li
                    class="menu-item"
                    routerLinkActive="menu-item-active"
                    aria-haspopup="true"
                  >
                    <a
                      class="menu-link"
                      routerLink="/finanzas/cxp/cuentas-por-pagar"
                      routerLinkActive="active"
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Cuentas por pagar</span>
                    </a>
                  </li>
                  <!-- end::3 Level -->
                  <!-- begin::3 Level -->
                  <li
                    class="menu-item"
                    routerLinkActive="menu-item-active"
                    aria-haspopup="true"
                  >
                    <a
                      class="menu-link"
                      routerLink="/finanzas/cxp/estado-de-cuenta"
                      routerLinkActive="active"
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Estado de cuenta</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li
              *ngIf="findPermiso('Bancos')"
              class="menu-item menu-item-submenu"
              routerLinkActive="menu-item-open"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link menu-toggle"
                routerLinkActive="active"
                href="/material/navigation"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Bancos</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <i class="menu-arrow"></i>
                <ul class="menu-subnav">
                  <!-- begin::3 Level -->
                  <li
                    class="menu-item"
                    routerLinkActive="menu-item-active"
                    aria-haspopup="true"
                  >
                    <a
                      class="menu-link"
                      routerLink="/finanzas/saldos"
                      routerLinkActive="active"
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Saldos</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <!-- end::2 Level -->
          </ul>
        </div>
      </li>

      <!-- Material-UI -->
      <!-- begin::1 Level -->
      <li
        *ngIf="findPermiso('Herramientas')"
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
      >
        <a
          class="menu-link menu-toggle"
          href="/material"
          routerLinkActive="active"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Tools/Tools.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Herramientas</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Herramientas</span>
              </span>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Aplicación de pagos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/herramientas/aplicacion-de-pagos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Aplicación de pagos</span>
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Importador de clientes')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/herramientas/importador-clientes"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Importador de clientes</span>
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Asignación de números de serie')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/herramientas/asignacion-series"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text"
                  >Asignación de números<br />de serie</span
                >
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Eliminación de ventas')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/herramientas/eliminacion-de-ventas"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Eliminación de ventas</span>
              </a>
            </li>

            <!-- Form Controls -->
            <!-- begin::2 Level -->
            <li
              *ngIf="findPermiso('Modificador de instalaciones y productos')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/herramientas/modificador-instalaciones"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text"
                  >Modificador de instalaciones<br />y productos</span
                >
              </a>
            </li>

            <li
              *ngIf="findPermiso('Promociones')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/compras/promociones"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Promociones</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Auditoria de inventarios')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/almacen/reporte-inventarios"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Auditoria de inventarios</span>
              </a>
            </li>
            <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/herramientas/importar-creditos"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Importar créditos CRM</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Créditos Asignados')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/herramientas/creditos-asignados"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Créditos Asignados CRM</span>
              </a>
            </li>
            <li
              *ngIf="findPermiso('Depurar CXC')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/herramientas/depurar-cxc"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Depurar CXC</span>
              </a>
            </li>

            <li
              *ngIf="findPermiso('Capacitate Relaciones')"
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/herramientas/capacitate-relaciones"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Capacitate Relaciones</span>
              </a>
            </li>
            <!-- end::2 Level -->
          </ul>
        </div>
      </li>

      <li
        class="menu-item capacitate"
        routerLinkActive="menu-item-open"
        [routerLink]="['/capacitate/modulos']"
      >
        <a
          class="menu-link"
          [ngClass]="{ 'is-active': router.url.includes('/capacitate/') }"
          routerLinkActive="is-active"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Tools/Compass.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Capacitate</span>
          <!-- <i class="menu-arrow"></i> -->
        </a>
        <!-- 
                <div class="menu-submenu">
                    <i class="menu-arrow"></i>
                    <ul class="menu-subnav">
                        <li class="menu-item menu-item-parent" aria-haspopup="true">
                            <span class="menu-link">
                <span class="menu-text">Capacitate</span>
                            </span>
                        </li>
                    </ul>
                </div> -->
      </li>

      <!-- end::1 Level -->
    </ul>
    <!-- end::Menu Nav -->
  </div>
  <!--end::Menu Container-->
</div>
<!--end::Aside Menu-->
